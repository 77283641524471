import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import generateUniqueId from 'utilities/generateUniqueId';
import { AcademyContactsWithAddress } from 'components/academyContacts';
import { AcademyApplyCard } from 'components/academyApplyCard';
import { GlobalStateContext } from 'containers/GlobalContextProvider';

const ApplyFooter = ({
  applicationDeadline,
  applicationLink,
  applicationText,
  title,
  academyLocations,
  contact,
  locations,
  trademark,
}) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  return (
    <div className="apply-footer" id="registration">
      <div className="apply-footer__frame">
        <h3 className="apply-footer__title">{`${
          translation['button.applyTo']
        } ${title}`}</h3>
        {academyLocations && academyLocations.length > 0 && (
          <div className="apply-footer__row">
            {academyLocations.map(() => (
              <div
                className="apply-footer__column apply-footer__column--single"
                key={generateUniqueId()}
              >
                <AcademyApplyCard
                  academyLocation={applicationText}
                  applicationDeadline={applicationDeadline}
                  applicationLink={applicationLink}
                  className="apply-card--single"
                />
              </div>
            ))}
          </div>
        )}
        <AcademyContactsWithAddress
          email={contact.email}
          locations={locations}
          trademark={trademark}
        />
      </div>
    </div>
  );
};

ApplyFooter.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      postalCode: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
    })
  ),
  contact: PropTypes.shape({
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }),
  academyLocations: PropTypes.arrayOf(PropTypes.string.isRequired),
  title: PropTypes.string.isRequired,
  applicationDeadline: PropTypes.string,
  applicationLink: PropTypes.string.isRequired,
  applicationText: PropTypes.string.isRequired,
  trademark: PropTypes.string.isRequired,
};

export { ApplyFooter };
