import React from 'react';
import PropTypes from 'prop-types';

import { HeroBannerLayout } from 'components/heroBanner';
import { getAcademyIcon } from 'utilities';

const AcademySingleHero = ({
  applicationLink,
  applicationText,
  backgroundImage,
  title,
  shortDescription,
  type,
  renderRightSide,
}) => {
  const ACADEMY_FOR = 'Academy for';

  return (
    <HeroBannerLayout
      className="hero-banner--featured-elements students-hero"
      backgroundImage={backgroundImage}
    >
      <div className="students-hero">
        <div className="students-hero__left-column">
          {title && (
            <div className="students-hero__title-wrapper">
              <figure className="students-hero__icon">
                <img src={getAcademyIcon(type)} alt={`Icon - ${title}`} />
              </figure>
              <h1 className="students-hero__title">
                {ACADEMY_FOR}
                <br />
                {title.split(ACADEMY_FOR)[1]}
              </h1>
            </div>
          )}
          {shortDescription && (
            <div
              className="students-hero__description"
              dangerouslySetInnerHTML={{ __html: shortDescription }}
            />
          )}
          <a
            className="students-hero__button btn"
            href={applicationLink}
            target="_blank"
            rel="noreferrer noopener"
          >
            {applicationText}
          </a>
        </div>
        <div className="students-hero__right-column">{renderRightSide()}</div>
      </div>
    </HeroBannerLayout>
  );
};

AcademySingleHero.propTypes = {
  applicationLink: PropTypes.string.isRequired,
  applicationText: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string,
  title: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  renderRightSide: PropTypes.func,
};

export { AcademySingleHero };
