import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { CMSLink } from 'components/cmsLink';
import generateUniqueId from 'utilities/generateUniqueId';
import { Lecture } from 'components/lecture';

const ProfessionalsSchedule = ({
  className,
  lectures,
  title,
  note,
  upcomingAcademySchedule,
}) => {
  const academyLocation = 'Chicago';
  const [openSchedule, setOpenSchedule] = useState(false);

  const sortedArray = lectures.sort((a, b) => {
    const lectureCity = academyLocation.toLowerCase();
    const startDateA = new Date(a[lectureCity].date);
    const startDateB = new Date(b[lectureCity].date);

    if (startDateA < startDateB) {
      return -1;
    }
    if (startDateA > startDateB) {
      return 1;
    }
    return 0;
  });

  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  const renderLectures = () => (
    <div className="students-schedule__lectures">
      {sortedArray.map((lecture, index) => {
        const lectureData = lecture[academyLocation.toLowerCase()];

        return (
          <Lecture
            key={generateUniqueId()}
            className={classNames(index > 2 && !openSchedule && 'hidden')}
            date={lectureData.date}
            description={lecture.description}
            duration={lectureData.duration}
            lecturers={lectureData.lecturers}
            location={lectureData.location}
            title={lecture.title}
            time={lectureData.time}
          />
        );
      })}
    </div>
  );

  const renderButton = () =>
    sortedArray &&
    sortedArray.length > 3 && (
      <button
        type="button"
        className={classNames(
          'btn btn--inverted students-schedule__lectures-button',
          { hidden: openSchedule }
        )}
        onClick={() => setOpenSchedule(true)}
      >
        {translation['button.showAll']}
      </button>
    );

  const renderNote = () =>
    note && (
      <p className="students-schedule__note">
        {note}
        {upcomingAcademySchedule &&
          upcomingAcademySchedule.title &&
          upcomingAcademySchedule.link && (
            <CMSLink
              className="students-schedule__link"
              linkUrl={upcomingAcademySchedule.link}
            >
              {` ${upcomingAcademySchedule.title}`}
            </CMSLink>
          )}
      </p>
    );

  return (
    <div
      className={classNames('students-schedule', className && `${className}`)}
    >
      <div className="students-schedule__frame">
        <h2 className="students-schedule__title">{title}</h2>
        {sortedArray && sortedArray.length > 0 && renderLectures()}
        {renderButton()}
        {renderNote()}
      </div>
    </div>
  );
};

ProfessionalsSchedule.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  note: PropTypes.string,
  upcomingAcademySchedule: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
  }),
  lectures: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      title: PropTypes.string.isRequired,
    })
  ),
};

export { ProfessionalsSchedule };
