import FlagIcon from 'images/flag.svg';
import DevbridgeLogo from 'images/devbridge-styled-logo.svg';
import SpeechBubblesIcon from 'images/speech-bubbles.svg';
import CertificateIcon from 'images/certificate.svg';

const getProcessIcon = (iconKeyword) => {
  switch (iconKeyword) {
    case 'devbridge':
      return DevbridgeLogo;
    case 'speech-bubbles':
      return SpeechBubblesIcon;
    case 'certificate':
      return CertificateIcon;
    default:
      return FlagIcon;
  }
};

export { getProcessIcon };
