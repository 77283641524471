import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import generateUniqueId from 'utilities/generateUniqueId';
import { AcademyAboutCard } from './AcademyAboutCard';

const AcademyAbout = ({ aboutCards, className }) =>
  aboutCards &&
  aboutCards.length > 0 && (
    <section
      className={classNames('academy-about', className && `${className}`)}
    >
      <div className="academy-about__frame">
        <div className="academy-about__row">
          {aboutCards.map((aboutCard) => (
            <div className="academy-about__column" key={generateUniqueId()}>
              <div className="academy-about__element">
                <AcademyAboutCard aboutCard={aboutCard} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );

AcademyAbout.propTypes = {
  aboutCards: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  className: PropTypes.string,
};

export { AcademyAbout };
