import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import generateUniqueId from 'utilities/generateUniqueId';
import { AcademyProcessCard } from './AcademyProcessCard';

const AcademyProcess = ({ className, processes, sectionTitle }) => {
  return (
    <>
      {processes && processes.length > 0 && (
        <div
          className={classNames('academy-process', className && `${className}`)}
        >
          <div className="academy-process__frame">
            <h2 className="academy-process__title">{sectionTitle}</h2>
            <div
              aria-label="Accordion Group Buttons"
              id="academyProcessAccordionGroup"
              className="academy-process__accordion"
            >
              {processes.map((process, index) => (
                <AcademyProcessCard
                  key={generateUniqueId()}
                  index={index}
                  process={process}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

AcademyProcess.propTypes = {
  className: PropTypes.string,
  processes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      iconKeyword: PropTypes.string.isRequired,
    })
  ),
  sectionTitle: PropTypes.string.isRequired,
};

export { AcademyProcess };
