import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { getFullMonthAndDay } from 'utilities';
import CircledArrow from 'icons/circled-arrow.svg';
import './scss/academy-apply-card.scss';

const AcademyApplyCard = ({
  academyLocation,
  applicationDeadline,
  applicationLink,
  className,
}) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  return (
    <a
      href={applicationLink}
      target="_blank"
      rel="noreferrer noopener"
      className={classNames('apply-card', className)}
    >
      {academyLocation && (
        <h3 className="apply-card__title">
          {
            translation[
              `academyInfo.label.location.in.${academyLocation.toLowerCase()}`
            ]
          }
        </h3>
      )}
      <div className="apply-card__wrapper">
        {applicationDeadline && (
          <div className="apply-card__info-wrapper">
            <p className="apply-card__info-tag">
              {translation['academyInfo.label.deadline']}
            </p>
            <p className="apply-card__info">
              {getFullMonthAndDay(
                applicationDeadline,
                languageContext.language.id
              )}
            </p>
          </div>
        )}
        <span className="apply-card__link btn-link btn-link--circled-arrow">
          {translation['button.applyNow']}
          <CircledArrow />
        </span>
      </div>
    </a>
  );
};

AcademyApplyCard.propTypes = {
  applicationDeadline: PropTypes.string,
  applicationLink: PropTypes.string.isRequired,
  academyLocation: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export { AcademyApplyCard };
