import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import generateUniqueId from 'utilities/generateUniqueId';
import { GlobalStateContext } from 'containers/GlobalContextProvider';

const AcademyInfo = ({ location, duration, tuition, tags }) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  return (
    <div className="students-hero-info">
      <div className="students-hero-info__wrapper">
        <p className="students-hero-info__title">
          {translation['academyInfo.label.location']}
        </p>
        <p className="students-hero-info__description">{location}</p>
      </div>
      <div className="students-hero-info__wrapper">
        <p className="students-hero-info__title">
          {translation['academyInfo.label.duration']}
        </p>
        <p className="students-hero-info__description">{duration}</p>
      </div>
      <div className="students-hero-info__wrapper">
        <p className="students-hero-info__title">
          {translation['academyInfo.label.tuition']}
        </p>
        <p className="students-hero-info__description">{tuition}</p>
      </div>
      {tags && tags.length > 0 && (
        <div className="students-hero-info__wrapper">
          <p className="students-hero-info__title">
            {translation['academyInfo.label.learn']}
          </p>
          <ul className="students-hero-info__list">
            {tags.map((tag) => (
              <li
                className="students-hero-info__list-item"
                key={generateUniqueId()}
              >
                {tag}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

AcademyInfo.propTypes = {
  location: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  tuition: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
};

export { AcademyInfo };
