import React from 'react';
import PropTypes from 'prop-types';

const AcademyAboutCard = ({ aboutCard }) => (
  <div className="academy-about-card">
    <div className="academy-about-card__content">
      <h3 className="academy-about-card__title">{aboutCard.title}</h3>
      <p className="academy-about-card__description">{aboutCard.description}</p>
    </div>
  </div>
);

AcademyAboutCard.propTypes = {
  aboutCard: PropTypes.shape({
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

export { AcademyAboutCard };
