import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { getProcessIcon } from '../getProcessIcon';

const AcademyProcessCard = ({ index, process }) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  const academyProcessRef = useRef(null);
  const [academyProcessOpen, setAcademyProcessOpen] =
    index === 0 ? useState(true) : useState(false);
  const toggleAcademyProcess = () => setAcademyProcessOpen(!academyProcessOpen);

  const handleKeyDown = (e) => {
    // eslint-disable-next-line default-case
    switch (e.key) {
      case 'ArrowDown':
        if (academyProcessRef.current) {
          e.preventDefault();
          if (academyProcessRef.current.nextSibling) {
            academyProcessRef.current.nextSibling.children[0].focus();
          } else {
            academyProcessRef.current.parentElement.firstChild.children[0].focus();
          }
        }
        break;
      case 'ArrowUp':
        if (academyProcessRef.current) {
          e.preventDefault();
          if (academyProcessRef.current.previousSibling) {
            academyProcessRef.current.previousSibling.children[0].focus();
          } else {
            academyProcessRef.current.parentElement.lastChild.children[0].focus();
          }
        }
        break;
      case 'Home':
        if (academyProcessRef.current) {
          e.preventDefault();
          academyProcessRef.current.parentElement.firstChild.children[0].focus();
        }
        break;
      case 'End':
        if (academyProcessRef.current) {
          e.preventDefault();
          academyProcessRef.current.parentElement.lastChild.children[0].focus();
        }
        break;
    }
  };

  return (
    <div
      className={classNames(
        'academy-process-card',
        academyProcessOpen && 'open'
      )}
      ref={academyProcessRef}
    >
      <button
        type="button"
        className="academy-process-card__button"
        aria-expanded={academyProcessOpen}
        aria-controls={`academyProcessCardDescription${index}`}
        id={`academyProcessCard${index}`}
        onClick={toggleAcademyProcess}
        onKeyDown={handleKeyDown}
      >
        <span className="academy-process-card__number">
          {`${translation['academyProcess.card.step'] || 'Step'} ${index + 1}:`}
        </span>
        <span className="academy-process-card__title">{process.title}</span>
        <span className="academy-process-card__toggle-icon" />
      </button>
      <div
        aria-labelledby={`academyProcessCard${index}`}
        id={`academyProcessCardDescription${index}`}
        className="academy-process-card__description-wrapper"
        role="region"
      >
        <div className="academy-process-card__description">
          <figure className="academy-process-card__icon">
            <img
              src={getProcessIcon(process.iconKeyword)}
              alt={`${process.iconKeyword} icon.`}
            />
          </figure>
          {process.description && process.description !== '' && (
            <div
              className="academy-process-card__description-text"
              dangerouslySetInnerHTML={{ __html: process.description }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

AcademyProcessCard.propTypes = {
  index: PropTypes.number.isRequired,
  process: PropTypes.shape({
    description: PropTypes.string.isRequired,
    iconKeyword: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

export { AcademyProcessCard };
